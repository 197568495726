/* You can add global styles to this file, and also import other style files */
body{
    max-width: 100%;
    overflow-x: hidden !important;
    word-wrap: break-word;
    }
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "~ngx-bootstrap/datepicker/bs-datepicker";

